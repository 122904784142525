import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import logo from "../assets/logo.png";
import jobs from "../utils/jobs.json"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import img1 from "../assets/1.jpg"
import img2 from "../assets/2.jpeg"
import img3 from "../assets/3.jpg"
import img4 from "../assets/4.jpg"
import img5 from "../assets/5.jpg"
import lofi from "../assets/lofi.jpg"
import lofi2 from "../assets/lofi2.jpg"
import lofi3 from "../assets/lofi1.png"
import lofi9 from "../assets/lofi9.webp"

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Home: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [email, set_search] = useState<string>("")
  const [user_option, set_user_option] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  const setOption=(item:any)=>{
    localStorage.setItem('profession',item)
    set_user_option(item)
    navigate("/signup")
  }

 if(window.innerWidth > 650){
  return (


    <div style={{margin:0, backgroundImage:"url("+lofi+")",  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:200, paddingRight:200,paddingBottom:200}} >

        <img src={logo} style={{height:110 ,borderRadius:20, margin:20, marginBottom:40}}/>

        {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'black'}}>Aap kya karte hain ?</p> */}

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:5, height:105, width:'100%'}}>

          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5,margin:5}}><div style={{paddingTop:10,fontSize:16}}>Promote Your Business<p style={{fontSize:10,fontStyle:'italic', marginTop:5}}>For business owners, entreprenuers, retailers etc.</p></div></Button>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:'80%', borderRadius:5, margin:5}}><div style={{paddingTop:10,fontSize:16}}>Promote Yourself <p style={{fontSize:10,fontStyle:'italic', marginTop:5}}>For musicians, artists, dancers, actors etc.</p></div></Button>

        </div>

        {/* <div style={{backgroundColor:"white", marginTop:40,marginBottom:20}}>
        <p style={{color:'white',marginTop:40, textAlign:'center'}}>What best describes you ?</p>
        <Container>
        <Row>
        {jobs.map((item:any,index)=>{
          return(
            <Col xs={12} sm={12} md={6} lg={3}>
              <Button onClick={(()=>setOption(item.category))} style={{backgroundColor:'black',width:'90%',height:'80%', color:'white', margin:10, fontSize:18, textAlign:'center', borderRadius:5, padding:50}}>
                {item.category}
                </Button>
              </Col>

          )
        })}
        </Row>
        </Container>
        </div> */}

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:100, height:200, width:'100%'}}>

          <img src={img2} style={{width:'40%', margin:10}}/>
          <div style={{padding:10}}>
          <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
        </div>


        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <div style={{padding:10}}>
        <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
          <img src={img3} style={{width:'40%', margin:10}}/>
        </div>

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <img src={img4} style={{width:'40%', margin:10}}/>

          <div style={{padding:10}}>
          <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
        </div>


        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <div style={{padding:10}}>
        <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
          <img src={img3} style={{width:'40%', margin:10}}/>
        </div>

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <img src={img4} style={{width:'40%', margin:10}}/>

          <div style={{padding:10}}>
          <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
        </div>


        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <div style={{padding:10}}>
        <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
          <img src={img3} style={{width:'40%', margin:10}}/>
        </div>

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <img src={img4} style={{width:'40%', margin:10}}/>

          <div style={{padding:10}}>
          <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
        </div>

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <div style={{padding:10}}>
        <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
          <img src={img3} style={{width:'40%', margin:10}}/>
        </div>

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <img src={img4} style={{width:'40%', margin:10}}/>

          <div style={{padding:10}}>
          <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
        </div>


        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <div style={{padding:10}}>
        <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
          <img src={img3} style={{width:'40%', margin:10}}/>
        </div>

        <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:50, height:200, width:'100%'}}>
        <img src={img4} style={{width:'40%', margin:10}}/>

          <div style={{padding:10}}>
          <p>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
          <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, borderRadius:20}}>Connect</Button>
          </div>
        </div>

     </div>
    

);


 }
 else{

  return (


    <div style={{margin:0, backgroundImage:"url("+lofi+")",  flexDirection:'column', zIndex:99999, minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:10, paddingLeft:20, paddingRight:20,paddingBottom:200, maxWidth:windowSize.innerWidth}} >

    <img src={logo} style={{height:110 ,borderRadius:20, margin:20, marginBottom:40}}/>

    {/* <p style={{marginTop:-40, marginBottom:40, fontStyle:'italic', color:'black'}}>Aap kya karte hain ?</p> */}

    <div style={{display:'flex',flexDirection:"column", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:5, height:105, width:windowSize.innerWidth, maxWidth:windowSize.innerWidth}}>

      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:windowSize.innerWidth-20, borderRadius:5,margin:5}}><div style={{paddingTop:10,fontSize:12}}>Promote Your Business<p style={{fontSize:8,fontStyle:'italic', marginTop:5}}>For business owners, entreprenuers, retailers etc.</p></div></Button>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', padding:10, backgroundColor:"black",color:'white', height:90, width:windowSize.innerWidth-20, borderRadius:5, margin:5}}><div style={{paddingTop:10,fontSize:12}}>Promote Yourself <p style={{fontSize:8,fontStyle:'italic', marginTop:5}}>For musicians, artists, dancers, actors etc.</p></div></Button>

    </div>


    <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:70, height:'auto', width:windowSize.innerWidth}}>

      <img src={img2} style={{width:'40%', margin:10}}/>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
    </div>


    <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>
    <div style={{padding:10}}>
    <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
    <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
    </div>
      <img src={img3} style={{width:'40%', margin:10}}/>
    </div>

    <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>

      <img src={img2} style={{width:'40%', margin:10}}/>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      </div>


      <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      <img src={img3} style={{width:'40%', margin:10}}/>
      </div>

      <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>

      <img src={img2} style={{width:'40%', margin:10}}/>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      </div>


      <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      <img src={img3} style={{width:'40%', margin:10}}/>
      </div>

      <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>

      <img src={img2} style={{width:'40%', margin:10}}/>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      </div>


      <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      <img src={img3} style={{width:'40%', margin:10}}/>
      </div>

      <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>

      <img src={img2} style={{width:'40%', margin:10}}/>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      </div>


      <div style={{display:'flex',flexDirection:"row", justifyContent:'center',alignItems:'center', backgroundColor:"white", marginTop:20, height:'auto', width:windowSize.innerWidth}}>
      <div style={{padding:10}}>
      <p style={{fontSize:10}}>Kaamka.com has helped me increase my visibility to potential customers by 5-6x easily. Now, I have a simple, easy to use digital presence which I can manage and which helps me connect with customers.</p>
      <Button onClick={()=>navigate("/signup")} variant="contained" style={{textAlign:'center', backgroundColor:"black",color:'white', height:40, width:200, fontSize:10, borderRadius:20}}>Connect</Button>
      </div>
      <img src={img3} style={{width:'40%', margin:10}}/>
      </div>







 </div>

);
 }

  
};

export default Home;

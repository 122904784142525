import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import menu from "../assets/menu.png"
import { useNavigate } from "react-router-dom";
import {db, auth} from "../App"


function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


interface IProps {
  screenName?: string
}

const Header: React.FC<IProps> =  ({ screenName  }: IProps) => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [user,set_user] = useState<string>("")
  const current = auth.currentUser

  console.log('current' ,current)

  const navigate = useNavigate();


  useEffect(() => {

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, []);


  function getUser(){

    let user = localStorage.getItem("user")

    if(user !== undefined && user !== null){
      set_user(user)
    }
    else{
      set_user("")
    }
  }

  if(window.innerWidth > 650 && window.google !== undefined){

  return (
    <div className="col-md-12" id="header">

      <div style={{flex:1}}>
      <img src={menu} style={{height:22,width:22, float:'left', marginTop:14, marginLeft:10}}/>
      <h1 style={{fontSize:28, marginLeft:20, marginTop:8, textAlign:'center', float:'left', color:'black', fontFamily:'Montserrat', fontWeight:700}}>Area</h1>
      {window.innerWidth>650
      ?
      <p style={{color:'black', float:'left',marginLeft:10,marginTop:19, textAlign:'center'}}>Building Connections</p>
      :
        null
      }
      </div>

      {window.innerWidth > 650
      ?
      <div style={{flex:1, flexDirection:'row', justifyContent:'end',alignItems:'center', display:'flex'}}>
      <p style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>About</p>
      <p style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>Mission</p>
      <p style={{color:'black', float:'left',marginLeft:10,marginTop:18, marginRight:20, textAlign:'center'}}>Privacy</p>     
      {user !== ""
      ?
      null
      :
      <Button onClick={()=>navigate("/login")} variant="contained" style={{float:'right', fontWeight:700, paddingLeft:30, paddingRight:30, fontSize:12, marginRight:20, backgroundColor:"#00bfff"}}>Log In</Button>
      } 
      </div>
      :
      <div style={{flex:1, flexDirection:'row', justifyContent:'end',alignItems:'center', display:'flex'}}>

      {auth.currentUser !== null
      ?
      null
      :
      <Button variant="contained" onClick={()=>navigate("/login")} style={{float:'right', fontWeight:700, paddingLeft:30, paddingRight:30, fontSize:12, marginRight:20, backgroundColor:"#00bfff"}}>Log In</Button>
      }       
      </div>
      }


    </div>
  )
  }
  else{

    return (
      <div id="header">
  
        <div style={{flex:1, flexDirection:'row', justifyContent:'flex-start',alignItems:'center',display:'flex'}}>
        <img src={menu} style={{height:20,width:20, marginLeft:10,marginTop:0, marginBottom:0,}}/>
        <p style={{fontSize:24, marginTop:0, marginBottom:0,marginLeft:10,  color:'black', fontFamily:'Montserrat', fontWeight:700}}>Area</p>
        </div>
  

        <div style={{flex:1, flexDirection:'row', alignItems:'flex-end',justifyContent:'flex-end', display:'flex'}}>
  
        {auth.currentUser !== null
        ?
        null
        :
        <Button variant="contained" onClick={()=>navigate("/login")} style={{float:'right',marginTop:0, marginBottom:0, fontWeight:700, paddingLeft:20, paddingRight:20, fontSize:10, backgroundColor:"#00bfff"}}>Log In</Button>
        }       
        </div>
        
  
  
      </div>

    )
  }
};

export default Header;
